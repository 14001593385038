import AndroidIcon from '@/assets/platform-icons/android-icon-small.svg?react';
import IosIcon from '@/assets/platform-icons/apple-icon.svg?react';
import { useMediaQuery } from '@/hooks';
import { useIsFreePlan } from '@/hooks/use-user-plan';
import { format, formatDistanceToNow } from 'date-fns';
import { ChevronDown, MessageSquareText } from 'lucide-react';
import { useState } from 'react';

import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { toast } from '@/components/ui/use-toast';

import { useRemoveRequest, useUpdateRequest } from '@/api/requests/queries';
import { Request } from '@/api/requests/types';

import { STATUSES, StatusType } from '../-utils/config';
import { ChangeStatusDropdown } from './change-status-dropdown';
import { ConfirmDeleteAlert } from './confirm-delete-alert';
import { RequestVoteCount } from './request-vote-count';

type Props = {
  data: Request[];
  onItemClick: (id: string) => void;
};

type QuickActionsType = {
  tooltip: string;
};

const QUICK_ACTIONS_BY_STATUS: Record<string, Array<StatusType & QuickActionsType> | undefined> = {
  pending: [
    { ...STATUSES.approved, tooltip: 'Approve' },
    { ...STATUSES.rejected, tooltip: 'Reject' },
  ],
  approved: [{ ...STATUSES['in-progress'], tooltip: 'Mark as in progress' }],
  'in-progress': [{ ...STATUSES.done, tooltip: 'Mark as completed' }],
};

export const RequestsList = ({ data, onItemClick }: Props) => {
  const [requestToRemove, setRequestToRemove] = useState<Request | null>(null);
  const updateMutation = useUpdateRequest();
  const removeMutation = useRemoveRequest();
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const isFreePlan = useIsFreePlan();

  const handleDeleteAction = (confirmed: boolean) => {
    if (!confirmed) {
      return setRequestToRemove(null);
    }

    if (!requestToRemove) {
      return;
    }

    setRequestToRemove(null);
    return removeMutation.mutateAsync(
      { requestId: requestToRemove.id, projectId: requestToRemove.projectId },
      { onSuccess: () => toast({ title: 'Request removed' }) },
    );
  };

  const handleChangeStatus = (request: Request, status: string) => {
    return updateMutation.mutateAsync(
      {
        requestId: request.id,
        projectId: request.projectId,
        data: {
          name: request.name,
          description: request.description,
          status,
          note: request.note,
          publicNote: request.publicNote,
        },
      },
      { onSuccess: () => toast({ title: 'Request status updated' }) },
    );
  };

  return (
    <div className="divide-y divide-dotted">
      {data?.map((request) => {
        const status = STATUSES[request.status];
        const quickActions = QUICK_ACTIONS_BY_STATUS[request.status];

        if (!isDesktop) {
          return (
            <div
              key={request.id}
              className="flex min-h-[70px] cursor-pointer flex-col gap-1 px-3 py-3 transition-colors hover:bg-slate-100/30 dark:hover:bg-slate-900/40"
              onClick={() => {
                onItemClick(request.id);
              }}
            >
              <div className="flex flex-1 items-center gap-3">
                <RequestVoteCount count={request.voteCount} />
                <div className="flex-1 flex-col">
                  <div className="line-clamp-2 text-lg font-semibold">{request.name}</div>
                  <div className="mt-1 line-clamp-2 text-muted-foreground">
                    {request.description}
                  </div>
                </div>
              </div>
              <div className="flex flex-1 items-start gap-1.5">
                <div className="mr-auto mt-1 flex items-center gap-1.5 space-x-0.5 text-sm text-muted-foreground/60">
                  <span className="mr-1 flex w-[36px] items-center">
                    {!isFreePlan && request.commentCount > 0 && (
                      <>
                        <MessageSquareText className="mr-1" size={14} />
                        {request.commentCount}
                      </>
                    )}
                  </span>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>{formatDistanceToNow(request.createdAt)} ago</div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Created on {format(request.createdAt, 'PPpp')}</p>
                    </TooltipContent>
                  </Tooltip>
                </div>
                {quickActions?.map((quickAction) => (
                  <Tooltip key={quickAction.status}>
                    <TooltipTrigger asChild>
                      <Button
                        className="h-9 w-9 p-0"
                        variant="outline"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleChangeStatus(request, quickAction.status);
                        }}
                      >
                        <quickAction.Icon className={quickAction.iconClassName} size={18} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{quickAction.tooltip}</TooltipContent>
                  </Tooltip>
                ))}
                <ChangeStatusDropdown
                  currentStatus={request.status}
                  isWithRemoveOption
                  onSelect={(status) => handleChangeStatus(request, status)}
                  onRemove={() => setRequestToRemove(request)}
                >
                  <Button className="h-9 min-w-9 space-x-1 p-2" variant="secondary">
                    {status && (
                      <status.Icon className={`${status.iconClassName} flex-shrink-0`} size={16} />
                    )}
                    <ChevronDown className="text-muted-foreground" size={18} />
                  </Button>
                </ChangeStatusDropdown>
              </div>
            </div>
          );
        }

        return (
          <div
            key={request.id}
            className="flex min-h-[70px] cursor-pointer items-center gap-3 px-3 py-3 transition-colors hover:bg-slate-100/30 dark:hover:bg-slate-900/40"
            onClick={() => {
              onItemClick(request.id);
            }}
          >
            <RequestVoteCount count={request.voteCount} />
            <div className="flex-1 flex-col">
              <div className="line-clamp-2 text-lg font-semibold">{request.name}</div>
              <div className="mt-1 line-clamp-2 text-muted-foreground">{request.description}</div>
              <div className="mt-1 flex items-center gap-1.5 space-x-0.5 text-sm text-muted-foreground/60">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div>{formatDistanceToNow(request.createdAt)} ago</div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Created on {format(request.createdAt, 'PPpp')}</p>
                  </TooltipContent>
                </Tooltip>
                {!isFreePlan && request.commentCount > 0 && (
                  <>
                    <span>•</span>
                    <span className="flex items-center">
                      <MessageSquareText className="mr-1" size={14} />
                      {request.commentCount}
                    </span>
                  </>
                )}
                {request.platform && (
                  <>
                    <span>•</span>
                    <span>
                      {request.platform === 'ios' ? (
                        <IosIcon width={16} height={16} />
                      ) : (
                        <AndroidIcon width={18} height={18} />
                      )}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="flex items-start gap-1.5">
              {quickActions?.map((quickAction) => (
                <Tooltip key={quickAction.status}>
                  <TooltipTrigger asChild>
                    <Button
                      className="h-9 w-9 p-0"
                      variant="outline"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleChangeStatus(request, quickAction.status);
                      }}
                    >
                      <quickAction.Icon className={quickAction.iconClassName} size={18} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{quickAction.tooltip}</TooltipContent>
                </Tooltip>
              ))}
              <ChangeStatusDropdown
                currentStatus={request.status}
                isWithRemoveOption
                onSelect={(status) => handleChangeStatus(request, status)}
                onRemove={() => setRequestToRemove(request)}
              >
                <Button className="h-9 min-w-9 space-x-1 p-2" variant="secondary">
                  {status && (
                    <status.Icon className={`${status.iconClassName} flex-shrink-0`} size={16} />
                  )}
                  <ChevronDown className="text-muted-foreground" size={18} />
                </Button>
              </ChangeStatusDropdown>
            </div>
          </div>
        );
      })}
      <ConfirmDeleteAlert isOpen={!!requestToRemove} onAction={handleDeleteAction} />
    </div>
  );
};
